import { Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import StorySubHeading from './StorySubHeading';

const style = {
  root: {
    marginBottom: '2.5rem',
    whiteSpace: 'nowrap',
  },
  link: {
    textDecoration: 'none',
  },
};

function StoryListItem(props) {
  const { classes, story } = props;
  return (
    <Grid className={classes.root}>
      <Link className={classes.link} to={`/read/${story.id}`}>
        <Typography color="primary" variant="h5">
          {story.title}
        </Typography>
      </Link>
      <StorySubHeading story={story} />
      <Typography variant="body1" gutterBottom={true}>
        {story.blurb}
      </Typography>
      {story.children}
    </Grid>
  );
}

StoryListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  story: PropTypes.object.isRequired,
};

export default withStyles(style)(StoryListItem);
