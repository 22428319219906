import React from 'react';
import { Avatar, Grid, Typography } from '@material-ui/core';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { makeStyles } from '@material-ui/styles';
import SocialMediaLink from './SocialMediaLink';

const Bio = () => {
  const useStyles = makeStyles({
    authorInfo: {
      marginBottom: '4.375rem',
    },
    avatar: {
      height: '10rem',
      marginRight: '1.5rem',
      width: '10rem',
    },
  });

  const classes = useStyles();

  return (
    <Grid className={classes.authorInfo} container alignItems="center">
      <Avatar
        alt="Kellen Geneva"
        className={classes.avatar}
        src="/kellen-avatar.jpg"
      />
      <Grid>
        <Typography color="primary" component="h2" gutterBottom variant="h3">
          Kellen Geneva
        </Typography>
        <Typography variant="subtitle1">
          Writer of Horror and Supernatural Thrillers
        </Typography>
        <Grid
          className={classes.socialMedia}
          justify="flex-end"
          container
          spacing={1}
        >
          <SocialMediaLink
            href="https://facebook.com/kellenmgeneva"
            icon={faFacebookF}
          />
          <SocialMediaLink
            href="https://twitter.com/kellengeneva"
            icon={faTwitter}
          />
          <SocialMediaLink
            href="https://www.instagram.com/kellengenevaauthor"
            icon={faInstagram}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Bio;
