import React from 'react';
import { Typography, Link } from '@material-ui/core';

export default [
  {
    blurb: `...must be granted.`,
    id: 'a-sailors-wish',
    postDate: '2019-09-15',
    title: `A Sailor's Wish`,
    url: '/a-sailors-wish.md',
    wordCount: 2772,
  },
  {
    blurb: 'Friday the 13th',
    id: 'shadow-man',
    postDate: '2019-09-13',
    title: 'Shadow Man',
    url: '/shadow-man.md',
    wordCount: 2312,
  },
  {
    blurb: `It's my fault...`,
    id: 'dear-elly-im-sorry',
    postDate: '2019-08-13',
    title: `Dear Elly, I'm Sorry`,
    url: '/dear-elly-im-sorry.md',
    wordCount: 2069,
  },
  {
    blurb: 'The accounts of Dr. Sicily Harmon.',
    id: 'red-oak-lane',
    postDate: '2019-02-09',
    title: 'Red Oak Lane',
    url: '/red-oak-lane.md',
    wordCount: 6900,
    children: (
      <Typography>
        <span role="img" aria-label="movie camera">
          🎥
        </span>{' '}
        As mentioned in{' '}
        <Link
          target="_blank"
          href="https://www.amazon.com/Bad-Ben-Way-Nigel-Bach/dp/B07QW3GZTN"
        >
          Bad Ben: The Way In
        </Link>
      </Typography>
    ),
  },
  {
    blurb: `Would you?`,
    id: 'a-walk-in-paris',
    postDate: '2019-03-01',
    title: 'A Walk in Paris',
    url: '/a-walk-in-paris.md',
    wordCount: 4348,
  },
  {
    blurb: `Is it all in Lindsey's head...or in her closet?`,
    id: 'hidden-inside',
    postDate: '2019-02-09',
    title: 'Hidden Inside',
    url: '/hidden-inside.md',
    wordCount: 1173,
  },
  {
    blurb: 'Where it all began.',
    id: 'we-all-fall-down',
    postDate: '2019-02-09',
    title: 'We All Fall Down',
    url: '/we-all-fall-down.md',
    wordCount: 699,
  },
];
