import { Typography } from '@material-ui/core';
import React from 'react';

const About = () => (
  <React.Fragment>
    <Typography variant="h4">About the Author</Typography>

    <Typography gutterBottom paragraph variant="body1">
      Kellen Geneva is an author residing in Olathe, KS where she is a wife and
      mother of two. A love for fiction has given Kellen the drive to write
      fantasy novels, short stories and screenplays.
    </Typography>

    <Typography gutterBottom paragraph variant="body1">
      Before beginning her writing career, she had the privilege of touring
      Europe with the Kansas Ambassadors of Music, and also performed through
      North America in places such as the Alamo in San Antonio, Texas, the Field
      Museum in Chicago, Illinois, and aboard the Carnival Fantasy while
      cruising Mexico. Her travels exposed her to different cultures, which also
      appear in her stories as her characters live out their own experiences.
    </Typography>

    <Typography paragraph variant="body1">
      In 2016, she received her Bachelor of Arts degree in Creative Writing with
      a focus in Fiction from Southern New Hampshire University. She is a member
      of the International English Honor Society, and National Society of
      Leadership and Success.
    </Typography>
  </React.Fragment>
);

export default About;
