import React from 'react';
import Bio from './Bio';
import StoryList from './StoryList';
import stories from '../stories';
import './Home.css';
import { Typography } from '@material-ui/core';
import About from './About';
import PaperContainer from './PaperContainer';

const Home = () => {
  return (
    <div className="home">
      <div className="home__header MuiPaper-root MuiPaper-rounded MuiPaper-elevation1" />
      <div className="home__main">
        <PaperContainer>
          <Bio />
          <Typography gutterBottom variant="h4" color="primary">
            Available Now
          </Typography>
          <div className="home__book-ad">
            <img
              className="home__book-cover"
              src="/hidden-inside-cover.jpg"
              alt="Hidden Inside"
            />
            <div>
              <Typography gutterBottom variant="body1">
                Wicked things hide in the shadows, but what are they and where
                did they come from? Hidden Inside & Other Shorts will take you
                on a journey from the darkest corner of your closet through the
                entrance into our world - and to the fallen angel who governs it
                all.
              </Typography>
              <Typography variant="body1">
                <a
                  href="https://www.amazon.com/Hidden-Inside-Shorts-Kellen-Geneva-ebook/dp/B082WLQRFR"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Amazon
                </a>
              </Typography>
            </div>
          </div>
          <hr />
          <div className="home__book-ad">
            <img
              className="home__book-cover"
              src="/dream-encounters-cover.jpg"
              alt="Dream Encounters"
            />
            <div>
              <Typography gutterBottom variant="body1">
                Adrie Woods thought she had settled into her new life as a
                werewolf, keeping the secrets of her past away from the pack.
                But when she meets Brian, a rogue vampire with a dark history
                pursuing him, everything changes. A prophecy is fulfilled and an
                ancient evil awakens, invading Adrie’s dreams and choosing her
                to help set in motion a plan made centuries ago. The voice in
                her dreams calls to Adrie’s unrealized identity, the “Dream
                Walker.”
              </Typography>
              <Typography variant="body1">
                When a mysterious book is delivered to Adrie, unwanted attention
                is brought from outsiders seeking its power. The past is not all
                that it seems. Secrets will be uncovered as Adrie finds herself
                thrown into the midst of a battle much more dangerous than the
                forbidden love that manifests between her and Brian. Dynamics
                are shifted, and things will never be the same.
              </Typography>
              <Typography variant="body1">
                <a
                  href="https://www.amazon.com/gp/product/B07YJ4SG3R"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Amazon
                </a>
              </Typography>
            </div>
          </div>
          <div className="home__about">
            <About />
          </div>
        </PaperContainer>
      </div>
      <div className="home__stories">
        <PaperContainer>
          <Typography color="primary" gutterBottom variant="h3">
            Short Stories
          </Typography>
          <StoryList stories={stories} />
        </PaperContainer>
      </div>
    </div>
  );
};

export default Home;
