import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const style = {
  root: {
    marginBottom: '1.5rem',
  },
  link: {
    textDecoration: 'none',
  },
};

function SecondaryHeader({ classes }) {
  return (
    <header className={classes.root}>
      <Link className={classes.link} to="/">
        <Typography color="primary" variant="h6">
          Kellen Geneva
        </Typography>
      </Link>
    </header>
  );
}

SecondaryHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(SecondaryHeader);
