import { Container } from '@material-ui/core';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import Home from './Home';
import Read from './Read';

const App = () => {
  return (
    <Container>
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" render={() => <Redirect to="/" />} />
          <Route path="/read/:storyId" component={Read} />
        </Switch>
      </Router>
    </Container>
  );
};

export default App;
