import { Typography } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

function StorySubHeading({ story }) {
  const minutesToRead = Math.ceil(story.wordCount / 250);
  const postDate = moment(story.postDate).format('MMMM D, YYYY');
  return (
    <Typography gutterBottom variant="subtitle2">
      {postDate} - {minutesToRead} min read
    </Typography>
  );
}

StorySubHeading.propTypes = {
  story: PropTypes.object.isRequired,
};

export default StorySubHeading;
