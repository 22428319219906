import PropTypes from 'prop-types';
import React from 'react';
import StoryListItem from './StoryListItem';

function StoryList({ stories }) {
  return stories.map((story, index) => (
    <StoryListItem key={index} story={story} />
  ));
}

StoryList.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default StoryList;
