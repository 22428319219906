import { useEffect, useState } from 'react';

const useStory = url => {
  const [story, setStory] = useState();
  useEffect(() => fetchStory(), []);
  return story;

  function fetchStory() {
    fetch(url).then(response => {
      response.text().then(markdown => {
        setStory(markdown);
      });
    });
  }
};

export default useStory;
