import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { withRouter } from 'react-router-dom';
import SecondaryHeader from './SecondaryHeader';
import StorySubHeading from './StorySubHeading';
import stories from '../stories';
import useStory from '../useStory';
import PaperContainer from './PaperContainer';

const style = {
  gutterBottom: {
    marginBottom: '1.5rem',
  },
};

const renderers = {
  paragraph: props => <Typography {...props} paragraph variant="body1" />,
};

function Read(props) {
  const { classes, match } = props;
  const story = stories.find(story => story.id === match.params.storyId);
  const storyText = useStory(story.url);
  return (
    <PaperContainer>
      <SecondaryHeader />
      {story && (
        <React.Fragment>
          <div className={classes.gutterBottom}>
            <Typography variant="h4">{story.title}</Typography>
            <StorySubHeading story={story} />
          </div>
          <ReactMarkdown renderers={renderers} source={storyText} />
        </React.Fragment>
      )}
    </PaperContainer>
  );
}

Read.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(withStyles(style)(Read));
