import { createMuiTheme } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: teal,
    error: red,
  },
  typography: {
    useNextVariants: true,
  },
});

export default theme;
