import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Link } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';

const SocialMediaLink = ({ href, icon }) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    link: {
      color: theme.palette.primary.main,
      fontSize: '1.5rem',
      textDecoration: 'none',
      padding: '0 0.5rem',
    },
  });
  const classes = useStyles();
  return (
    <Grid item>
      <Link
        className={classes.link}
        color="inherit"
        href={href}
        rel="noopener"
        target="_blank"
      >
        <FontAwesomeIcon icon={icon} />
      </Link>
    </Grid>
  );
};

SocialMediaLink.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

export default SocialMediaLink;
